export const WAZZUP_ID_PLACEHOLDER = '::$wazzupIdPlaceholder'
export const WAZZUP_ID_HTML = '<span class="badge wazzupIdPlaceholder" contenteditable="false" draggable="true">ID: XXXX-XXXX</span>'

export const TG_FEEDBACK_URL = 'https://t.me/WidgetProfeat_bot'

export const MY_BUTTON_DEFAULT_SETTINGS = {
  redirect: 'whatsapp-and-telegram',
  modal_title: 'Мы на связи в мессенджерах',
  whatsappBtn: 'Написать в WhatsApp',
  telegramBtn: 'Написать в Telegram',
  separatorText: 'или с телефона',
  background: 'linear-gradient(225deg, #df3fbf 0%, #56a2f4 100%)',
  isDarkMode: true,
  qrCodeEnabled: true,
  isDelayMode: false,
  branding: true
}
